<template>
  <div>
    <v-card outlined>
      <v-card-title class="title">
        Students' Attendance Report
        <v-spacer></v-spacer>

        <download-button @action="printAttendance()" v-if="students.length"
          >Download Attendance Report</download-button
        >
      </v-card-title>

      <v-card-title class="title pb-0">
        <v-flex xs3>
              <v-student-search-field
                  @onClear="onClear"
                  @onSelectStudent="selectStudent"
                ></v-student-search-field>
        </v-flex>
        <v-flex xs2>
          <v-select
            :loading="loadingGrade"
            :disabled="grades.length < 1"
            @change="getSections()"
            :items="[{ value: 'all', text: 'All' }, ...grades]"
            outlined
            dense
            class="pa-0"
            label="Select grade"
            v-model="grade"
          />
        </v-flex>
        <v-flex xs2>
          <v-select
            :loading="loadingSection"
            :disabled="sections.length < 1"
            :items="sections"
            class="pa-0"
            label="Select section"
            outlined
            dense
            v-model="section"
          />
        </v-flex>
        <v-flex xs2>
          <v-select
            :items="years"
            :disabled="!grade"
            class="pa-0"
            label="Select Year"
            outlined
            dense
            v-model="saal"
          />
        </v-flex>
        <v-flex xs3>
          <v-select
            :disabled="!saal"
            :items="months"
            class="pa-0"
            outlined
            dense
            label="Select Months"
            v-model="month"
          />
        </v-flex>
        <!-- <v-flex xs1>
          <search-button
            :disabled="!month"
            :permission="true"
            @action="getData()"
          >
          </search-button>
        </v-flex> -->
      </v-card-title>
    </v-card>
    <v-container>
      <template>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card elevation="0">
              <div class="scroll-aligner">
                <div
                  class="table-wrapper name_roll"
                  style="width: 100%"
                  v-if="calendar.length"
                >
                  <table class="data">
                    <thead class="header">
                      <tr style="border-bottom: 1px solid black">
                        <th>Roll</th>
                        <th style="padding: 26px;" width="400px">Name</th>
                        <th>Grade</th>
                      </tr>
                    </thead>
                    <tbody class="results">
                      <tr v-for="(d, index) in students" :key="index">
                        <th>{{ d.roll }}</th>
                        <th>{{ d.name }}</th>
                        <th>{{ d.grade_name }} ({{ d.section_name }})</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-wrapper calendar_info" v-if="calendar.length">
                  <table class="data">
                    <thead class="header">
                      <tr style="border-bottom: 1px solid black">
                        <!--<th>Roll</th>-->
                        <!--<th width="200px">Name</th>-->
                        <th
                          v-for="(n, i) in calendar"
                          :key="i"
                          :class="getHolidayClass(n, (h = 1))"
                        >
                          {{ n.gate_nep }}
                          <!-- <p>{{ days[n.baar] }}</p> -->
                          <p>{{ n.baar}}</p>
                        </th>
                        <th width="200px;">Total Present Day</th>
                        <th>Total Absent Day</th>
                        <th>Total Late Day</th>
                        <th>Total Leave Day</th>
                      </tr>
                    </thead>
                    <tbody class="results">
                      <tr v-for="(d, index) in students" :key="index">
                        <!--<th>{{d.roll}}</th>-->
                        <!--<th>{{d.name}}</th>-->
                        <!--<td>{{d}}</td>-->
                        <td
                          v-for="(n, i) in calendar"
                          :key="i"
                          :class="getHolidayClass(n, (h = 0))"
                          style="text-align: center;position: relative"
                        >
                          <span
                            v-if="
                              attendanceDay.includes(
                                parseInt(nepNumberToEng(n.gate_nep))
                              )
                            "
                          >
                            <span
                              v-if="
                                d.absent.includes(
                                  parseInt(nepNumberToEng(n.gate_nep))
                                )
                              "
                              class="absent"
                              >A</span
                            >
                            <span v-else class="present">P</span>

                            <span
                              class="late"
                              v-if="
                                d.late.includes(
                                  parseInt(nepNumberToEng(n.gate_nep))
                                )
                              "
                              >(late)</span
                            >
                            <span
                              class="leave"
                              v-if="
                                d.leave.includes(
                                  parseInt(nepNumberToEng(n.gate_nep))
                                )
                              "
                              >(leave)</span
                            >
                          </span>
                          <span v-else>
                            <span v-if="n.baar === 7">-</span>
                            <span v-else-if="schoolEventDetail(n.gate_nep)">
                              -
                              <!--<span v-if="schoolEventDetail(n.gate_nep).is_holiday !== 1">-</span>-->
                            </span>
                            <span v-else>-</span>
                            <!--{{n.baar === 7 ?'' : '-'}}-->
                          </span>
                          <span
                            class="tooltiptext"
                            style="position: absolute;top:30px;left: 30px;width: 150px;"
                            >{{
                              schoolEventDetail(n.gate_nep)
                                ? schoolEventDetail(n.gate_nep).event_title
                                : ""
                            }}</span
                          >
                        </td>
                        <td style="text-align: center">
                          {{ d.present.length + d.late.length }}
                        </td>
                        <td style="text-align: center">
                          {{ d.leave.length + d.absent.length }}
                        </td>
                        <td style="text-align: center">{{ d.late.length }}</td>
                        <td style="text-align: center">{{ d.leave.length }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </template>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import {
  getPropertyFromArrayObject,
  nepNumberToEng,
  engNumberToNep,
} from "@/library/helpers";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form({}, "/api/attendance/daily"),
    grades: [],
    printUrl: "",
    tab: null,
    grade: "",
    section: "",
    sections: [],
    calendar: [],
    loadingGrade: false,
    loadingSection: false,
    month: "",
    attendanceDay: [],
    months: [
      { value: "01", text: "Baisakh" },
      { value: "02", text: "Jestha" },
      { value: "03", text: "Ashad" },
      { value: "04", text: "Shrawan" },
      { value: "05", text: "Bhadra" },
      { value: "06", text: "Asoj" },
      { value: "07", text: "Kartik" },
      { value: "08", text: "Mangsir" },
      { value: "09", text: "Poush" },
      { value: "10", text: "Magh" },
      { value: "11", text: "Falgun" },
      { value: "20", text: "Chaitra" },
    ],
    days: [],
    years: [{ value: nd.format("YYYY"), text: nd.format("YYYY") }],
    saal: "",
    schoolEvents: [],
    schoolEventDates: [],
    students: [],
    enrollCode:'',
    pagination: {
      rowsPerPage: 100,
      sortBy: "roll",
    },
  }),

  computed: {
    ...mapState(["batch", "batches"]),
  },
  mounted() {
    this.getGrades();
    this.getCalendar();
    this.days[1] = "Sun";
    this.days[2] = "Mon";
    this.days[3] = "Tue";
    this.days[4] = "Wed";
    this.days[5] = "Thu";
    this.days[6] = "Fri";
    this.days[7] = "Sat";
  },
  watch: {
    month: function(v) {
      if (v) {
        this.get();
        this.getCalendar();
        this.getSchoolEvents();
      }
    },
    section: function() {
      this.calendar = [];
      this.saal = nd.format("YYYY");
      this.month = "";
    },
    grade: function() {
      this.calendar = [];
      this.saal = "";
      this.month = "";
    },
    saal: function() {
      this.calendar = [];
      this.month = "";
    },
    batches: function() {
      let $this = this;
      // this.years.push(this.nd.format('YYYY'));
      this.batches.map(function(item) {
        if ($this.batch.is_hs)
          if (item.is_current_batch && item.is_hs) {
            $this.years = [];
            $this.years.push({ value: item.year_start, text: item.year_start });
            $this.years.push({ value: item.year_end, text: item.year_end });
          }
      });
    },
  },
  methods: {
    getData(){
      this.get();
      this.getCalendar();
      this.getSchoolEvents();
    },
    onClear(){
      this.enrollCode = '';
      this.get();

    },
    selectStudent(student) {
      if(student){
        this.enrollCode = student.enroll_code;
        if(this.grade && this.section && this.month && this.saal )
          this.get()
      }
    },
    nepNumberToEng,
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&gradeId=" +
        this.grade +
        "&sectionId=" +
        this.section +
        "&month=" +
        this.month +
        "&year=" +
        this.saal + 
        "&enrollCode="+
        this.enrollCode
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.students = data.students;
        this.attendanceDay = data.attendance_day;
        this.printUrl = data.download_url;
      });
    },

    getGrades() {
      this.sections = [];
      this.section = "";
      this.loadingGrade = true;
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally((err) => {
          this.loadingGrade = false;
        });
    },
    getSections() {
      if(this.grade !== 'all'){
        this.section = "";
        this.sections = [];
        this.loadingSection = true;
        this.$rest
          .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
          .then(({ data }) => {
            this.sections = data.data.map((item) => {
              return { value: item.id, text: item.name };
            });
          })
          .finally(() => {
            this.loadingSection = false;
          });
      }else{
        this.section = "";
        this.sections = [];
      }
    },
    getCalendar() {
      this.$rest
        .get("api/main-calendar?saal=" + this.saal + "&mahina=" + this.month)
        .then((response) => {
          this.calendar = response.data.data;
        });
    },
    getSchoolEvents() {
      this.$rest
        .get("api/calendar?saal=" + this.saal + "&mahina=" + this.month)
        .then((response) => {
          this.schoolEvents = response.data.data;
          this.schoolEventDates = [];
          response.data.data.map((res) => {
            if (res.event_date)
              this.schoolEventDates.push(
                engNumberToNep(res.event_date.split("-")[2])
              );
          });
        });
    },
    getHolidayClass(n, h) {
      let className = "";
      if (n.baar === 7) className += "saturday ";
      if (n.baar === 7 && h === 0) {
        className += "cell_bottom";
      }
      if (this.schoolEventDates.includes(n.gate_nep)) {
        let event = this.schoolEventDetail(n.gate_nep);
        className += "event_day ";

        if (event && event.is_holiday) {
          className += "sat ";
          if (h === 0) {
            className += "cell_bottom ";
          }
        } else {
          className += "event_color ";
        }

        className += "tootltip ";
      }
      return className;
    },
    schoolEventDetail(gate_nep) {
      let date =
        this.saal +
        "-" +
        this.month +
        "-" +
        parseInt(nepNumberToEng(gate_nep)).pad();
      return this.schoolEvents.find((res) => res.event_date === date);
      // return gate_nep;
    },
    printAttendance() {
      window.open(this.printUrl+"&saal="+this.saal+"&mahina="+this.month);
    },
  },
};
</script>
