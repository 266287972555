<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="overlay reportings">
          <v-card class="primary--border" outlined>
            <v-tabs v-model="tab" icons-and-text>
              <v-tabs-slider></v-tabs-slider>
              <v-tab class="icon-style-section" href="#tab-1">
                <div class="icon-analysis">
                  <v-icon class="v-icon-style">all_inbox</v-icon>
                  <div class="icon-description">
                    <h2>Daily</h2>
                  <p>Daily Report</p>
                  </div>
                </div>
              </v-tab>

              <v-tab class="icon-style-section" href="#tab-2">
                <div class="icon-analysis">
                  <v-icon class="v-icon-style">calendar_view_month</v-icon>
                  <div class="icon-description">
                  <h2>Monthly</h2>
                  <p>Monthly Report</p>
                  </div>
                </div>
              </v-tab>
              <v-tab class="icon-style-section" href="#tab-5">
                <div class="icon-analysis">
                  <v-icon class="v-icon-style">calendar_view_month</v-icon>
                  <div class="icon-description">
                  <h2>Yearly</h2>
                  <p>Yearly Report</p>
                  </div>
                </div>
              </v-tab>

              <v-tab class="icon-style-section" href="#tab-3">
                <div class="icon-analysis">
                  <v-icon class="v-icon-style">fact_check</v-icon>
                  <div class="icon-description">
                  <h2>Absent List</h2>
                  <p>Absent List Report</p>
                  </div>
                </div>
              </v-tab>

              <v-tab class="icon-style-section" href="#tab-4">
                <div class="icon-analysis">
                  <v-icon class="v-icon-style">pending</v-icon>
                  <div class="icon-description">
                  <h2>Attendance Pending</h2>
                  <p>Attendance Pending Report</p>
                  </div>
                </div>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item :key="1" value="tab-1">
                <v-card flat>
                  <attendance :isReport="true"></attendance>
                  <!--coll-->
                </v-card>
              </v-tab-item>
              <v-tab-item :key="2" value="tab-2">
                <v-card flat>
                  <monthly></monthly>
                  <!--coll-->
                </v-card>
              </v-tab-item>
              <v-tab-item :key="3" value="tab-3">
                <v-card flat>
                  <absent-list></absent-list>
                </v-card>
              </v-tab-item>
              <v-tab-item :key="4" value="tab-4">
                <v-card flat>
                  <attendance-pending></attendance-pending>
                </v-card>
              </v-tab-item>
              <v-tab-item :key="5" value="tab-5">
                <v-card flat>
                  
                  <yearly></yearly>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <div :class="'inner-overlay ' + (isCurrentBatch ? 'dont-show' : '')">
            <p>
              <v-icon style="font-size: 50px; color: red">warning</v-icon>
              <br />
              Attendance Reports are allowed only in the current batch. <br />
              <span style="font-size: 15px"
                >Please make sure you have current batch selected in your action
                bar.</span
              >
            </p>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import {
	getPropertyFromArrayObject,
	nepNumberToEng,
	engNumberToNep,
} from "@/library/helpers";

import Monthly from "./pages/monthly";
import AbsentList from "./pages/absent-list";
import AttendancePending from "./pages/attendance-pending";
import Attendance from "../../../../components/Attendance";
import Yearly from "./pages/yearly";
export default {
	components: { Monthly, AbsentList, AttendancePending, Attendance, Yearly },
	data: () => ({
		tab: "tab-1",
	}),

	computed: {
		...mapState(["batch", "batches"]),
		isCurrentBatch() {
			let $this = this;
			let output = false;
			this.batches.map(function (batch) {
				if ($this.batch.id === batch.id && batch.is_current_batch === true) {
					output = true;
				}
			});
			return output;
		},
	},
	mounted() {},
	watch: {},
	methods: {},
};
</script>
<style lang="scss">
.scroll-aligner {
  display: flex;
  .name_roll {
    width: 1500px;
  }
  .calendar_info {
  }
}

.v-tabs__slider {
  .accent {
    color: #82b1ff !important;
  }
}

.overlay.reportings .inner-overlay p {
  margin-top: 15%;
}

.table-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}

.table-wrapper table {
  border: 1px solid black;
  border-collapse: collapse;
}

.table-wrapper table tr td,
.table-wrapper table tr th {
  padding: 5px 20px;
  border: 1px solid black;
  /*width: 100px;*/
  text-align: center;
}

.sat {
  background: #ff5252;
  color: white;
}

.saturday {
  background-color: #ff5252;
  color: white;
  /*color: #ff5252;*/
}

.present {
  /*color: #4caf50;*/
}

.absent {
  /*color: #ff5252;*/
}

.late {
  font-size: 8px;
  /*color:#fb8c00*/
}

.leave {
  font-size: 8px;
  /*color: #1976d2*/
}

.event_day {
  color: white;
}

.event_color {
  background: #4caf50;
}

td.event_day.tootltip {
  cursor: pointer;
}

/* Show the tooltip text when you mouse over the tooltip container */
td.event_day.tootltip:hover .tooltiptext {
  visibility: visible !important;
  color: white;
}

.tooltiptext {
  visibility: hidden;
  width: 500px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: relative;
  z-index: 9999;
}

.v-slide-group__content {
  justify-content: space-between;
}

.icon-style-section {
  flex-direction: column !important;
  .icon-analysis {
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 3px;
    .v-icon-style {
      margin-right: 10px;
      margin-top: 6px;
    }
    h2 {
      font-size: 16px;
      // line-height: 30px;
      margin-top: 5px;
      margin-bottom: 2px;
      text-align: left;
    }
    p {
    font-size: 10px;
    margin: 0px;
    text-transform: none;
    color: #8f8f8f;
    text-align: left;
    // margin-left: -5px;
  }
  }
  
}

/*.cell_bottom {*/
/*border: white !important;*/
/*}*/
</style>
